import React from 'react';
import '../styles/pagination.css';

const Pagination = ({
  currentPage,
  totalPages,
  hasArticles,
  handlePaginationClick,
}) => {
  const maxPagesToShow = 3; // Change this value to adjust the number of pages to display

  const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  return (
    <div className="pagination">
      <div>Continue exploring our guide</div>
      <div className="pagination__page-numbers">
        {currentPage > 1 && (
          <button
            title="First page"
            className="pagination-item"
            onClick={() => {
              handlePaginationClick(1);
            }}
          >
            {'<'}
          </button>
        )}

        {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const pageIndex = startPage + index;
          return (
            <button
              key={pageIndex}
              className={`pagination-item ${
                currentPage === pageIndex ||
                (pageIndex === 1 && currentPage === 0)
                  ? 'active'
                  : ''
              }`}
              onClick={() => {
                handlePaginationClick(pageIndex);
              }}
            >
              {pageIndex}
            </button>
          );
        })}

        {currentPage < totalPages - 1 && (
          <button
            title="Last page"
            className="pagination-item"
            onClick={() => {
              handlePaginationClick(totalPages - 1);
            }}
          >
            {'>'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
