import React, { useState, useEffect } from 'react';
import Pagination from '../components/pagination';
import NoResults from '../components/no-results';
import Layout from '../components/layout';
import Filters from '../templates/filters';
import { AdUnit } from '../components/adunit';
import ArticlePreview from '../components/article-preview';
import { LocationsController } from '../components/locations-controller';
import filterUnapprovedEntries from '../filter-unapproved-entries';
import buildGuidePagePath from '../build-guide-page-path';
import MobileResults from '../components/mobile-results';
import { useMobile } from '../hooks/use-mobile';
import TIMEFRAMES from '../timeframes';
import CATEGORIES from '../categories';
import LOCATIONS from '../locations';
import TRANSPORT from '../transport';
import { Link } from 'gatsby';

export const BrowseAllContainer = ({
  slug,
  nodes,
  locations,
  transit,
  category,
  subcategory,
  ads,
  bannerAd,
}) => {
  // how many articles to display per page
  const pageLength = 8;

  // filter out any that:
  // - do not have any subcategories
  // - are unapproved
  nodes = filterUnapprovedEntries(nodes.filter(n => n.subcategories));
  let numResults = nodes.length;
  // const totalPages = Math.ceil(numResults / pageLength);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(numResults / pageLength)
  );
  const resultsProps = {
    section: 'deals',
    num: numResults,
    subcategory: LOCATIONS[subcategory]?.label,
    category: CATEGORIES[category]?.label,
    transit: TRANSPORT[transit]?.label,
  };

  // start at page zero
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');

  // the index from which to start pagination
  const startIndex = currentPage * pageLength;

  // set initial visible nodes based on start values
  const [visibleNodes, setVisibleNodes] = useState(
    nodes.slice(startIndex, startIndex + pageLength)
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };
  const [searchOpen, setSearchOpen] = useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  const hasArticles = true;
  const buildPath = buildGuidePagePath;
  const isMobile = useMobile();
  const handleSearchInput = async event => {
    const searchString = event.target.value.toLowerCase();
    setSearchString(searchString);
    const filteredSearch = nodes.filter(result =>
      result.title.toLowerCase().includes(searchString)
    );
    setVisibleNodes(filteredSearch.slice(1, 1 + pageLength));
    setTotalPages(filteredSearch.length / pageLength);
  };

  // load more
  const handlePaginationClick = i => {
    // one more page
    const nextPage = i;
    setCurrentPage(nextPage);

    // update the start
    const startIndex = nextPage * pageLength;
    // get more nodes
    const nodesToAdd = nodes.slice(startIndex, startIndex + pageLength);
    // and add them in to existing
    setVisibleNodes([...nodesToAdd]);
  };

  //maps through each article and makes a flatmap of the routes system values
  //then maps through the routes and counts the number of each route
  const getRoutesFromArticle = article =>
    (article.body?.json?.content || [])
      .filter(
        node =>
          node?.nodeType === 'embedded-entry-block' &&
          node?.data?.target?.sys?.contentType?.sys?.contentful_id === 'header'
      )
      .map(node =>
        node?.data?.target?.fields?.routes['en-US']?.map(
          r => r?.fields?.system['en-US']
        )
      )
      .flat();

  const catCount = {
    'Arts + Culture': 0,
    'Eat + Drink +Shop': 0,
    Outdoors: 0,
    'Sessions At...': 0,
  };
  const locCount = {
    Connecticut: 0,
    'Hudson Valley': 0,
    'Long Island': 0,
    NYC: 0,
  };
  const transitCount = {
    'Metro-North': 0,
    LIRR: 0,
    Subway: 0,
  };

  const categoryCounts =
    nodes.length > 0
      ? nodes.reduce((counts, article) => {
          const category = article.categories[0].title;
          catCount[category] = (catCount[category] || 0) + 1;
          return catCount;
        }, {})
      : {};

  const locationCounts =
    nodes.length > 0
      ? nodes.reduce((counts, article) => {
          const location = article.subcategories[0].title;
          locCount[location] = (locCount[location] || 0) + 1;
          return locCount;
        }, {})
      : {};

  const transportCounts =
    nodes.length > 0
      ? nodes.reduce((counts, article) => {
          const transport = getRoutesFromArticle(article);

          transport.forEach(t => {
            transitCount[t] = (transitCount[t] || 0) + 1;
          });

          return transitCount;
        }, {})
      : {};
  const filterResults = { categoryCounts, locationCounts, transportCounts };

  useEffect(() => {
    filterOpen
      ? document.body.classList.add('hideScroll')
      : document.body.classList.remove('hideScroll');

    document.querySelector('header').classList.toggle('fixed', isMobile);
  }, [filterOpen, isMobile]);

  return (
    <Layout mainClass="section-landing" currentSection="articles">
      {isMobile && (
        <div
          id="searchButton__mobile"
          aria-label="Search"
          onKeyDown={handleSearchToggle}
          onClick={handleSearchToggle}
        >
          search
        </div>
      )}
      <div className="section-header">
        <div className="section-header-inner">
          <h1 className="section-title">Guides</h1>
        </div>
        {isMobile && !filterOpen && (
          <div className="section-filtering">
            <div className="mobile-results">
              {/* {subcategory || transit ? (
                <MobileResults {...resultsProps} />
              ) : (
                <p>{`${nodes.length} articles`}</p>
                )} */}
              <div className="location-controller__label ">Locations</div>
            </div>
            <button
              className="mobile-filter-btn"
              data-heading-filters
              onClick={() => {
                handleFilterToggle();
              }}
            >
              <span>Filters</span>
            </button>
          </div>
        )}
      </div>

      <div className="article-page">
        {isMobile && (
          <>
            <div className={`search mobile ${searchOpen ? '' : 'hide'}`}>
              <div className="filter-header-inner__mobile">
                <h1>
                  <Link to="/">
                    <img
                      src="/logo.png"
                      alt="MTA Away"
                      id="mta-logo__mobile-search"
                    />
                  </Link>
                </h1>
                <div
                  id="closeButton__mobile"
                  aria-label="Close"
                  onKeyDown={handleSearchToggle}
                  onClick={handleSearchToggle}
                  data-heading-filters
                >
                  close
                </div>
                <div className="search__mobile-wrapper">
                  <p className="event-filters__label">Search</p>
                  <span className="material-icons icon-search__filter">
                    search
                  </span>
                  <input
                    className="event-filter__search"
                    placeholder="i.e: Montauk"
                    type="text"
                    onChange={handleSearchInput}
                  />
                </div>
              </div>
            </div>

            <div className={`filters mobile ${filterOpen ? '' : 'hide'}`}>
              <Filters
                {...{
                  category,
                  results: nodes,
                  subcategory: slug,
                  transit,
                  buildPath,
                  hasArticles,
                  isMobile,
                  handleFilterToggle,
                }}
              />
            </div>
          </>
        )}
        <div className="mta-u-right-border">
          <LocationsController
            isMobile={isMobile}
            category={category}
            subcategory={slug}
            transit={transit}
            handleFilterToggle
            active={slug ? slug : 'all'}
            locations={locations}
          />
          <ul className="browse-all--articles">
            {!visibleNodes.length && (
              <div className="article__no-results-message">
                We didn’t find any results in the requested category. Please
                select a different category or try returning later.
              </div>
            )}
            {visibleNodes.map(article => (
              <li key={article.slug}>
                <ArticlePreview article={article} />
              </li>
            ))}
          </ul>
          {visibleNodes.length < totalPages && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              indexPass
              handlePaginationClick={handlePaginationClick}
            />
          )}
        </div>
        <div className="sidebar">
          <div className="sidebar-inner">
            {!isMobile && (
              <>
                <p className="event-filters__label">Search</p>
                <span className="material-icons icon-search__filter">
                  search
                </span>
                <input
                  className="event-filter__search"
                  placeholder="i.e: Montauk"
                  type="text"
                  onChange={handleSearchInput}
                />
                <Filters
                  {...{
                    filterResults,
                    category,
                    subcategory: slug,
                    results: nodes,
                    transit,
                    buildPath,
                    hasArticles,
                    isMobile,
                  }}
                />
              </>
            )}
            {ads && ads.map(ad => <AdUnit key={ad.id} ad={ad} />)}
          </div>
        </div>
      </div>
      {bannerAd && (
        <div className="banner-ad-wrapper">
          <AdUnit ad={bannerAd} classString="full-width-ad" />
        </div>
      )}
    </Layout>
  );
};
